<template>
  <div>
    <div class="row d-flex justify-content-center">
      <b-card class="card col-md-5 mx-1">
        <b-card-body class="card-body d-flex justify-content-center">
          <img
            :src="require('@/assets/images/logo/golog_b2bpro.png')"
            alt="B2BPro"
            class="logoHeight"
          />
        </b-card-body>
      </b-card>
      <b-card class="card col-md-5 mx-1">
        <b-card-body class="card-body d-flex justify-content-center">
          <img
            :src="require('@/assets/images/logo/golog_smarttruck.png')"
            alt="B2BPro"
            class="logoHeight"
          />
        </b-card-body>
      </b-card>
    </div>
    <div class="row d-flex justify-content-center">
      <b-card class="card col-md-5 mx-1">
        <b-card-body class="card-body">
          <b-row>
            <b-col>
              <feather-icon icon="ShoppingCartIcon" size="200" color="#D3455B" />
            </b-col>
            <b-col>
              <h1 class="title1">{{ b2b.activeOrders }}</h1>
              <h1 class="title2">ACTIVE ORDER</h1>
              <h1 class="title3">TODAY</h1>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card class="card col-md-5 mx-1">
        <b-card-body>
          <b-row>
            <b-col>
              <feather-icon icon="TruckIcon" size="200" color="#D3455B" />
            </b-col>
            <b-col>
              <h1 class="title1">{{ b2b.assignedOrdersToday }}</h1>
              <h1 class="title2">ASSIGNED ORDERS</h1>
              <h1 class="title3">TODAY</h1>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card class="card col-md-5 mx-1">
        <b-card-body>
          <b-row>
            <b-col>
              <feather-icon icon="TruckIcon" size="200" color="#D3455B" />
            </b-col>
            <b-col>
              <h1 class="title1">{{ b2b.scheduledOrders }}</h1>
              <h1 class="sched">SCHEDULED ORDERS</h1>
              <h1 class="title3">INCOMING</h1>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card class="card col-md-5 mx-1">
        <b-card-body>
          <b-row>
            <b-col>
              <feather-icon icon="TruckIcon" size="200" color="#D3455B" />
            </b-col>
            <b-col>
              <h1 class="title1">{{ b2b.assignedOrdersNextDay }}</h1>
              <h1 class="sched">ASSIGNED SCHEDULED ORDERS</h1>
              <h1 class="title3">INCOMING</h1>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>
    <b-modal
      id="policy-modal"
      title="Policy Notice"
      hide-footer
      hide-header
      size="lg"
      no-close-on-backdrop
    >
      <div class="p-2">
        <h1 class="text-primary">Terms and Conditions</h1>
        <b-row class="mb-2 mt-3">
          <b-col>
            <b-button
              @click="
                openModal(
                  'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/REFUND_POLICY.docx&embedded=true',
                  'Refund Policy'
                )
              "
              variant="outline-primary"
              class="w-100 text-primary custom-button"
            >
              Refund Policy
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <b-button
              @click="
                openModal(
                  'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/DATA_PRIVACY_POLICY.docx&embedded=true',
                  'Data Privacy Policy'
                )
              "
              variant="outline-primary"
              class="w-100 text-primary custom-button"
            >
              Data Privacy Policy
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <b-button
              @click="
                openModal(
                  'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/RULES_CONDUCT_POLICY.docx&embedded=true',
                  'Rules & Conduct Policy'
                )
              "
              variant="outline-primary"
              class="w-100 text-primary custom-button"
            >
              Rules & Conduct Policy
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <b-button
              @click="
                openModal(
                  'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/PRIVACY_TERMS_POLICY.docx&embedded=true',
                  'Privacy Terms Policy'
                )
              "
              variant="outline-primary"
              class="w-100 text-primary custom-button"
            >
              Privacy Terms Policy
            </b-button>
          </b-col>
        </b-row>
        <b-row class="justify-content-end align-items-center">
          <b-col cols="auto" class="d-flex align-items-center">
            <span @click="rejectPolicy" class="text-primary" style="cursor: pointer"> Cancel </span>
          </b-col>
          <b-col cols="auto">
            <b-button variant="primary" @click="acceptPolicy">Accept</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal v-model="showModal" :title="modalTitle" size="lg" hide-footer>
      <iframe :src="docUrl" width="100%" height="750px" frameborder="0"></iframe>
    </b-modal>
  </div>
</template>
<script>
import { BAlert } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BAlert,
  },
  data() {
    return {
      user: { name: 'Name' },
      b2b: [],
      policy: null,
      showModal: false,
      docUrl: '',
      modalTitle: '',
    }
  },
  created() {
    this.user = getUserData()
    this.getdata()
    this.checkPolicy()
  },
  methods: {
    async getdata() {
      const self = this
      const response = await this.$http.get('/dashboard')
      self.b2b = response.data.data
    },
    openModal(url, title) {
      this.docUrl = url
      this.modalTitle = title
      this.showModal = true
    },
    checkPolicy() {
      // const policy = JSON.parse(localStorage.getItem("policy"));
      const policy = 1
      this.policy = policy
    },
    acceptPolicy() {
      // Remove the policy from storage
      localStorage.removeItem('policy')

      // Hide the modal
      this.$bvModal.hide('policy-modal')
    },
    rejectPolicy() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      this.$router.push({ name: 'auth-login' })
    },
  },
  watch: {
    policy(newPolicy) {
      if (newPolicy === 1) {
        this.$bvModal.show('policy-modal')
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/Dashboard.scss';

.custom-button {
  font-weight: bold;
  font-size: 1.2em;
  border-color: lightgray !important;
}
</style>